<template>
    <div class="header">
        <leftBar :badge="badge"/>
        <div class="title">{{title}}</div>
        <slot name="right" />
    </div>
</template>

<style scoped lang="less">
.header {
    width: 100%;
    height: 12vw;
    padding: 0  16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 13;
    &>img {
        width: 5.86667vw;
        height: 5.86667vw;
        transition: all 0.3s;
    }
    &>.title {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
    }
    
}
</style>

<script>
import leftBar from './left-bar'
export default {
    components: { leftBar },
    props: ['title','badge']
}
</script>